import React from 'react';
import videos from "../../data/videos/videos";

import './OnFocus.css';

const OnFocus = () => {
	return (
		<div className="OnFocus">
			<a href="https://www.youtube.com/@MEDIACulAge" target="_blank">
                <img src={require("../../assets/img/youtube-logo.jpg")} style={{ width: "20vw", marginTop: "5vmin"}}/>
            </a>
            <h3 style={{ fontSize: "4vmin",margin: "5vmin" }}>Youtube Media CulAge</h3>
            <div className="VideosList">
                {videos.length && videos.map(video => (
                    <li className="VideoItem">
                        <div className="VideoItemContent">
                            {video.dom}
                            <h3>{video.title}</h3>
                        </div>
                    </li>
                ))}
            </div> 
		</div>
	)
}

export default OnFocus;