import React from 'react';
import './Idea.css';
import { useState } from 'react';

const structures = [
    {
        title: "Идентифициране, систематизиране и подбор на обекти на материалното и елементи на нематериалното културно наследство на област Стара Загора",
        children: [
            "Идентифициране на обектите на материалното и елементите на нематериалното културно наследство на област Стара Загора и преглед на специализирана научна литература, свързана с тях;",
            "Систематизиране на обектите на материалното и елементите на нематериалното културно наследство на област Стара Загора;",
            "Предварителен подбор на обекти на материалното и елементи на нематериалното културно наследство за представяне в експериментална онлайн платформа;",
            "Наблюдение и набиране на допълнителна информация на терен за актуалното състояние на предварително подбраните обекти и елементи на културното наследство с оглед техния окончателен подбор за представяне в експериментална онлайн платформа."
        ],
        shown: false
    },
    {
        title: "Концептуален модел за интегрирано дигитално представяне на културното наследство.",
        children: [
            "Проучване на световния и национален опит в областта на дигитализацията на културното наследство и представянето му в онлайн среда;",
            "Проучване и оценка на процеса на дигитализация и представяне на културното наследство на област Стара Загора в онлайн среда;",
            "Разработване на концептуален модел за интегрирано дигитално представяне на културното наследство на примера на област Стара Загора."
        ],
        shown: false
    },
    {
        title: "Експериментален модел на онлайн платформа за интегрирано представяне на културното наследство на област Стара Загора и разпространение на резултатите от проекта.",
        children: [
            "Разработване на идеен проект на онлайн платформа за представяне на културното наследство на област Стара Загора;",
            "Фото, видео и аудио документиране на селектираните обекти и елементи на културното наследство на област Стара Загора, обработка на фотографии, видеа и аудиозаписи и създаване на дигитални ресурси за представяне на културното наследство на област Стара Загора;",
            "Техническо изработване на експерименталната онлайн платформа за представяне на културното наследство на област Стара Загора и качване (ъплоудване) на създадените дигитални ресурси и продукти;",
            "Презентиране на изградената експериментална онлайн платформа за представяне на културното наследство на област Стара Загора пред заинтересовани страни – музеи, читалища, училищно образование, местни власти;",
            "Разпространение на резултатите от проекта."
        ],
        shown: false
    }
]

const Idea = () => {
    const [shownBoxes, setShownBoxes] = useState([false, false, false])

    const toggleBoxContent = (i) => {
        if (document.getElementById("StructureBoxContent"+i).style.display === "block") {
            document.getElementById("StructureBoxContent"+i).style.display = "none";
            let newShownBoxes = [...shownBoxes];
            let shownBox = {...newShownBoxes[i]}
            shownBox = false;
            newShownBoxes[i] = shownBox;
            setShownBoxes(newShownBoxes);
        }
        else {
            document.getElementById("StructureBoxContent"+i).style.display = "block";
            let newShownBoxes = [...shownBoxes];
            let shownBox = {...newShownBoxes[i]}
            shownBox = true;
            newShownBoxes[i] = shownBox;
            setShownBoxes(newShownBoxes);
        }        
    }
    
    return (
        <div className="Idea-page">
            <h1 className="BigTitle">ЗА ПРОЕКТА</h1>
            <div className="MediumTitle">
                <h2>РАЗРАБОТВАНЕ НА МОДЕЛ ЗА ИНТЕГРИРАНО ДИГИТАЛНО ПРЕДСТАВЯНЕ НА КУЛТУРНОТО НАСЛЕДСТВО</h2>
                <h2>(НА ПРИМЕРА НА ОБЛАСТ СТАРА ЗАГОРА)</h2>
            </div>
            <div className="MediumTitle">
                <h2>DEVELOPING A <span>M</span>ODEL FOR AN INT<span>E</span>GRATED <span>D</span>IG<span>I</span>TAL PRESENT<span>A</span>TION OF THE <span>CUL</span>TURAL HERIT<span>AGE</span></h2>
                <h2>(ON THE EXAMPLE OF STARA ZAGORA DISTRICT)</h2>
            </div>
            <img className="BigLogo" src={require("../../assets/img/logo/logo1.png")}></img>
            <h2 className="Subtitle">Идеята</h2>
            <div className="IdeaKeypoint">
                <img className="IdeaKeypointImage" src={require("../../assets/img/logo/logo1.png")}></img>
                <p className="IdeaKeypointText">Да се разработи концептуален модел за интегрирано дигитално представяне на регионално културно наследство.</p>
            </div>
            <div className="IdeaKeypoint">
                <img className="IdeaKeypointImage" src={require("../../assets/img/logo/logo2.png")}></img>
                <p className="IdeaKeypointText">Експериментално дигитално представяне на материални културни ценности, нематериално културно наследство, унаследени традиции, занаяти, фестивали, културни събития на област Стара Загора чрез създадени интегрирани дигитални продукти.</p>
            </div>
            <h2 className="Subtitle">Реализация на идеята</h2>
            <h3 className="Subsubtitle">Как:</h3>
            <ul className="IdeaHowList">
                <li className="IdeaHowListItem">
                    <p>Чрез научно изследване на чуждестранния и национален опит и разработване на алгоритъм като серия от последователни стъпки, чрез които се достига до модел за интегрирано дигитално  представяне на регионално културно наследство.</p>
                </li>
                <li className="IdeaHowListItem">
                    <p>Чрез създаване на различни по вид дигитални ресурси и организирането им в структурирана бази данни, които са траен архив за съхранение на културното наследство на област Стара Загора.</p>
                </li>
                <li className="IdeaHowListItem">
                    <p>Чрез тематична и териториална интеграция на създадените дигитални ресурси в дигитални продукти и тяхното онлайн представяне с оглед популяризиране на регионалното културно наследство.</p>
                </li>
            </ul>
            <h3 className="Subsubtitle">Ползи от реализация на идеята:</h3>
            <ul className="IdeaHowList">     
                <li className="IdeaHowListItem">
                    <p>Съхранено културно наследство</p>
                </li>
                <li className="IdeaHowListItem">
                    <p>Видимо културно наследство</p>
                </li>
                <li className="IdeaHowListItem">
                    <p>Достъпно културно наследство</p>
                </li>
            </ul>
            <h2 className="Subtitle">Структура на проекта</h2>
            <div className="StructureList">
            {structures.map((structure, i) => (
                <li className="StructureBox">
                    <div className="StructureBoxTitle">
                        <h3 style={{ background: "var(--rose)", padding: "0 10px"}}>{"РП" + (i+1)}</h3>
                        <h3>{structure.title}</h3>
                        <h3 style={{ cursor: "pointer" }}onClick={() => toggleBoxContent(i)}>{shownBoxes[i] ? "-" : "+"}</h3>
                    </div>
                    <ul id={"StructureBoxContent"+i} className="StructureBoxContent">
                        {structure.children.map(child => (
                            <li>
                                <p>{child}</p>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
            </div>
            <p className="SmallText">
            Проектът е финансиран от Фонд „Научни изследвания“ по програма за фундаментални научни изследвания на млади учени и постдокторанти – 2022 г. (договор № КП-06-М65/3 от 16.12.2022 с ръководител гл. ас. д-р Стела Стефанова и базова организация Институт за балканистика с Център по тракология – БАН.
            </p>
        </div>
    )
}

export default Idea;