import { Link, useLocation } from "react-router-dom";
import "./Accordion.css";
import { useEffect, useState } from "react";

export default function NavBar(props) {
    const [openFirstSubmenu, setOpenFirstSubmenu] = useState(false);
    const [openSecondSubmenu, setopenSecondSubmenu] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("")
    const location = useLocation();

    const toggleFirstSubmenu = () => {
        setOpenFirstSubmenu(!openFirstSubmenu);
        setopenSecondSubmenu(false)
    }

    const toggleSecondSubmenu = () => {
        setopenSecondSubmenu(!openSecondSubmenu);
    }

    const closeAllSubmenus = () => {
        setOpenFirstSubmenu(false);
        setopenSecondSubmenu(false);
    }

    const getFirstCurrentArrowSymbol = () => {
        if (openFirstSubmenu) 
            return "▼";
        else
            return "▶"
    }

    const getSecondCurrentArrowSymbol = () => {
        if (openSecondSubmenu) 
            return "▼";
        else
            return "▶"
    }

    useEffect(() => {
        if (location.pathname != currentLocation) {
            setCurrentLocation(location.pathname);
            closeAllSubmenus()
        }
    }, [location])

    return (
        <ul className="NavBarList">
            <li className="Navbar-item">
                <p className="Navbar-item-text" onClick={toggleFirstSubmenu}>{getFirstCurrentArrowSymbol() + "За проекта"}</p>
                {openFirstSubmenu && <div className="Navbar-item-submenu">
                    <Link to={"/idea"}><p className="Navbar-item-text">{"Идеята"}</p></Link>
                    <p className="Navbar-item-text" onClick={toggleSecondSubmenu}>{getSecondCurrentArrowSymbol() + "Резултати"}</p>
                </div>}
                {openSecondSubmenu && <div className="Navbar-item-subsubmenu">
                    <Link to={"/papers"}><p className="Navbar-item-text">Научни публикации</p></Link>
                    <Link to={"/reports"}><p className="Navbar-item-text">Научни доклади</p></Link>
                    <Link to={"/press"}><p className="Navbar-item-text">Други публикации</p></Link>
                </div>}
            </li>
            <li className="Navbar-item">
                <Link to={"/on-focus"}><p className="Navbar-item-text">На фокус</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/digital-resources"}><p className="Navbar-item-text">Дигитални изложби</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/catalogue"}><p className="Navbar-item-text">Тематичен каталог</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/calendar"}><p className="Navbar-item-text">Календар 2025</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/news"}><p className="Navbar-item-text">Новини</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/contact"}><p className="Navbar-item-text">Контакти</p></Link>
            </li>
        </ul>
    );
}

export function Accordion(props) {
    const [opened, setOpened] = useState(false);

    const openMenu = () => {
        setOpened(!opened)
        props.setCurrent(props.data.id);
    }

    const currentNavStyle = () => {        
        if (props.current === props.data.id) {
            return " current-nav"
        }
        else {
            return ""
        }
    }
    
    const childrenStyle = () => {
        if (props.topBar) {
            return { position: "absolute" }
        }
        return {}
    }

    const getCurrentArrowSymbol = () => {
        if (props.data.children && opened) 
            return "▼";
        else if (props.data.children)
            return "▶"
        else
            return ""
    }

    return (
        <li className="Navbar-item">
            {props.data.isPdf ? 
                <a href={props.data.link} target="_blank">
                    <p className={"Navbar-item-text " + props.data.style + currentNavStyle()} onClick={() => openMenu()}>{getCurrentArrowSymbol() + props.data.title}</p>
                    {props.data.children && opened && !props.collapseAllSubmenus}
                </a>
                :
                <Link to={props.data.link}>
                    <p className={"Navbar-item-text " + props.data.style + currentNavStyle()} onClick={() => openMenu()}>{getCurrentArrowSymbol() + props.data.title}</p>
                    {props.data.children && opened && !props.collapseAllSubmenus}
                </Link>
            }
            <div className="Navbar-item-children" style={childrenStyle()}>
                {props.data.children &&
                opened && !props.collapseAllSubmenus &&
                props.data.children.map((data, i) => (
                    <Accordion key={i} data={data} current={props.current} setCurrent={props.setCurrent} collapseAllSubmenus={props.collapseAllSubmenus}/>
                ))}
            </div>
        </li>
    );
}
