import { Link, useParams } from "react-router-dom";
import sights from "../../data/sights/sights_all"
import "./Sights.css"
import Slideshow from "../../components/Slideshow/Slideshow";

const Sight = () => {
	const { sightId } = useParams();
	const sightData = sights.find(sight => sight.id == sightId);
		
	return (
		<div className="Sight">
			{sightData ? 
			(<div className="SightData">
				<h1 className="SightDataTitle">{sightData.name}</h1>
				<div className="SightDataInfo">
					<div className="SightDataDescription">
						{sightData.paragraphs && sightData.paragraphs.map(paragraph => (
							<p className="SightDataParagraph">{paragraph}</p>
						))}
						{(sightData.sources?.length > 0) && (<p className="SightDataSources">Основни източници:</p>)}
						{sightData.sources?.map(source => (
							<p className="SightDataParagraph">{source}</p>
						))}
					</div>
					<div className="SightDataRight">
						{sightData.photosCount > 0 &&
						((sightData.photosCount === 1 ? 
						<div className="SightDataImage" style={{ backgroundImage:  `url(/photo_albums/id_${sightData.id}/${sightData.img[0]})` }} alt=""/>
						:
						<Slideshow albumData={sightData}></Slideshow>
						))}
						<div className="SightDataExtraInfo">
							{sightData.photosCount > 3 && <Link to={"/albums/" + sightData.id}>
								<img style={{width: "47px"}} src={require("../../assets/img/photo-album-icon.png")}/>
							</Link>}
							{sightData.youTubeLinks?.map(youTubeLink => (
								<a href={youTubeLink} target="_blank">
									<img style={{width: "50px"}} src={require("../../assets/img/youtube-icon-small.png")}/>
								</a>
							))}
							<h3>Местоположение: <span>{sightData.location}</span></h3>
							<h3>Достъп: <span>{sightData.access}</span></h3>
							<h3>Работно време: <span>{sightData.workingHours}</span></h3>
						</div>
					</div>
				</div>
			</div>)
			: <>Loading..</>}
		</div>
	)
}

export default Sight;