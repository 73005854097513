import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useState } from "react";
import "./Catalogue.css";
import { Slide } from "react-slideshow-image";

const pagesCount = 13;
const Catalogue = () => {
    const [ currentPage, setCurrentPage ] = useState(1); 

    const getPhotosContent = () => {
        let content = [];
        for (let i = 1; i < (pagesCount+1); i++) {
            content.push(
                <div className="SlideshowPhotoContainer" key={i}>
                    <div className="SlideshowPhoto" style={{ backgroundImage: `url(/catalogue/${i}.JPG)` }}>
                        {/* <span>Slide {index + 1}</span> */}
                    </div>
                </div>
            )
        }
        
        return content;
    }
    

	return (
		<div className="Catalogue-page">
            <div className="Catalogue-page-content">
                <Slide easing="ease" autoplay={false}>
                    {getPhotosContent()}
                </Slide>
            </div>
		</div>
	)
}

export default Catalogue;