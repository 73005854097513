const events = [
    {
        id: 1,
        title: "Днес",
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3))
    },
    {
        id: 2,
        title: "Ритуал „Розобер“, с. Кънчево",
        start: new Date(2025, 4, 24, 15, 30, 0),
        end: new Date(2025, 4, 24, 15, 30, 0),
    },
    {
        id: 3,
        title: "Ритуал „Розобер“, с. Черганово",
        start: new Date(2025, 4, 25, 15, 30, 0),
        end: new Date(2025, 4, 25, 15, 30, 0),
    },
    {
        id: 4,
        title: "Ритуал „Розобер“, с. Розово",
        start: new Date(2025, 4, 31, 15, 30, 0),
        end: new Date(2025, 4, 31, 15, 30, 0),
    },
    {
        id: 5,
        title: "„Тъпан бие, хоро се вие“ - национално надиграване, пл. „Севтополис“, Казанлък",
        start: new Date(2025, 4, 31, 15, 30, 0),
        end: new Date(2025, 4, 31, 15, 30, 0),
    },
    {
        id: 6,
        title: "Ритуал „Розобер“, с. Ясеново",
        start: new Date(2025, 5, 1, 15, 30, 0),
        end: new Date(2025, 5, 1, 15, 30, 0),
    },
    {
        id: 7,
        title: "Спектакъл по Коронация на Царица Роза 2025, пл. „Севтополис“, Казанлък",
        start: new Date(2025, 5, 6, 15, 30, 0),
        end: new Date(2025, 5, 6, 15, 30, 0),
    },
    {
        id: 8,
        title: "Ритуал „Розобер“, с. Розово",
        start: new Date(2025, 5, 7, 15, 30, 0),
        end: new Date(2025, 5, 7, 15, 30, 0),
    },
    {
        id: 9,
        title: "Ритуал „Розобер“, с. Овощник",
        start: new Date(2025, 5, 7, 15, 30, 0),
        end: new Date(2025, 5, 7, 15, 30, 0),
    },
    {
        id: 10,
        title: "Международен фолклорен фестивал, пл. „Севтополис“, Казанлък",
        start: new Date(2025, 5, 7, 15, 30, 0),
        end: new Date(2025, 5, 7, 15, 30, 0),
    },
    {
        id: 11,
        title: "Ритуал „Розобер“, край Казанлък",
        start: new Date(2025, 5, 8, 15, 30, 0),
        end: new Date(2025, 5, 8, 15, 30, 0),
    },
    {
        id: 12,
        title: "Международен фестивал на лавандулата, гр. Чирпан",
        start: new Date(2025, 5, 20, 15, 30, 0),
        end: new Date(2025, 5, 22, 15, 30, 0),
    },
    {
        id: 13,
        title: "Национален тракийски фолклорен събор  „Богородична стъпка“, с. Старозагорски бани",
        start: new Date(2025, 7, 30, 15, 30, 0),
        end: new Date(2025, 7, 31, 15, 30, 0),
    },
    {
        id: 14,
        title: "Научен семинар „Траки, вино и култура“",
        start: new Date(2025, 1, 21, 15, 30, 0),
        end: new Date(2025, 1, 23, 15, 30, 0),
    },
]

export default events;