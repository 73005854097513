const videos = [
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/xkEEGP4gwSE?si=1yCNOJmWXk-t99Q7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Крепост Асара, гр. Николаево"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/ewo3C9hBfS8?si=DJH37jEbS3fd9-9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Късноантична баня, гр. Гурково"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/MUBVtM_BOj0?si=-Wxnaye13RhuJOjs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Празник на розата 2023, с. Кънчево, гр. Гурково, гр. Крън"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/R1n86CId0rE?si=fKPrhCI7HwO-qF7U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Мегалит над с. Бузопвград и крепост Бузово кале"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/5MOb0itEOx4?si=I7xxu1neP31J2EKS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Паметник на Свободата на вр. Шипка"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/GMMW11wlzN4?si=IvGo0_l7siw4FlBV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "„Балада за човека“ (Веселин Ханчев) на вр. Човека, Осоговска планина"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/KNKQFlAJ1xs?si=SxUv6Zs6G4h8PdTB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Национален фестивал на маскарадните игри „Старци в Турия“ - 2024"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/3rl5bFBNkR0?si=rN3TB2rWbae3XiBk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Потопената църква „Св. Атанасий“, с. Виден"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/ZP54cima8t8?si=Szv1r7uhDGu4WHM-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Старите къщи - архитектурното наследство на обл. Стара Загора"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/V8NTm7jR5c0?si=Yi1MmWpNAlFyvFtt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Биорали с магарешки каручки в гр. Гурково, 2024 г."
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/M64bGDyO8OI?si=xjK4BxLtosy_nK5j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Фестивал на Античността и Средновековието „От Августа Траяна до Боруй“"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/VRTBC9d9Za4?si=d_tKJhcw5EHi4bCS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Buzludzha monument in Bulgaria - dissonant heritage. A look into the future."
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/bfxsARfqyCg?si=lPWxLyI3qxAV6nrv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Един ден в „Селския двор“. Национален тракийски фолклорен събор „Богородична стъпка“, 2024 г."
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/JbJQJkMmzxs?si=mc-8HNsCGG8-vZ2S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Църкви в област Стара Загора"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/zRfI5uhrp_Q?si=7Ma6hoB8ZYfnue0e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Фолклорни фестивали в област Стара Загора"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/lo4gKjW3BOw?si=dagQpxVqzObkD5ET" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Долината на тракийските царе"
    },
    {
        dom: <iframe height="300" width="300" src="https://www.youtube.com/embed/dgl9DLGKVhw?si=fU1ifugs4TW8wQD-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
        title: "Празник на розата, Казанлък, 2024 г."
    }
]

export default videos;