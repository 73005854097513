import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import "./Sights.css";

const Themes = () => {
	const [themes, setThemes] = useState([])
	const { type, themeId } = useParams();
	
	const themeStyle = (src) => ({
		backgroundImage: 'url(' + src + ')',
		backgroundPosition: 'center'
	})

	useEffect(() => {
		const importThemes = async () => {			
			const importedThemes = await import(`../../data/themes/${type}/cultural_heritage_${themeId}.js`);			
			setThemes(importedThemes.default);
		}

		importThemes();
	}, [themeId, type]);

	return (
		<div className="Themes">
			<div className="Themes-list">
				{themes.map(theme => (
					<Link to={`/themes/${type}/${themeId}/${theme.id}`} state={{ data: theme.children }}>
						<li className="Theme-list-item">
							<div className="Theme-item" style={themeStyle(theme.img)}>

							</div>
							<h2 className="Theme-item-title">{theme.name}</h2>
						</li>
					</Link>
				))}
			</div>
		</div>
	)
}

export default Themes;