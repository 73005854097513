import { useState } from "react";
import "./Calendar.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const CalendarPage = () => {
    const [numPages, setNumPages] = useState(12);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesRendered, setPagesRendered] = useState(0)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPagesRendered(0);
    }
    const onRenderSuccess = () => {
        setPagesRendered((v) => ++v);
    }
    const nextPage = () => {
        setPageNumber((v) => ++v);
    }
    const prevPage = () => {
        setPageNumber((v) => --v);
    }
    const getPageContent = () => {
        const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

        const isCurrentlyRendering = pagesRenderedPlusOne === 1;
        const isLastPage = numPages === pageNumber;
        const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;

        return (
            <Page
                key={`page_${pageNumber}`}
                onRenderSuccess={
                    needsCallbackToRenderNextPage ? onRenderSuccess : null
                }
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
            />
        );
    }
    return (
        <div className="Calendar-page">
        <div className="Calendar-buttons">
            <button onClick={prevPage} disabled={pageNumber <= 1}>
                Назад
            </button>
            <button onClick={nextPage} disabled={pageNumber >= (numPages ?? -1)}>
                Напред
            </button>
        </div>
        <div className="Calendar-content">
            <Document
                file={"/calendar/calendar.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
                className="my-react-pdf"
            >
                {
                    getPageContent()
                }
            </Document>
        </div>
        </div>
    );
}

export default CalendarPage;