const news = [
    {
        id: 1,
        title: "НАУЧЕН СЕМИНАР „ТРАКИ, ВИНО И КУЛТУРА“ ЩЕ СЕ ПРОВЕДЕ НА 21 И 22 ФЕВРУАРИ 2025 Г. В АРХЕОЛОКОМПЛЕКС „ДОЛИНАТА НА ТРАКИЙСКИТЕ ЦАРЕ“, ГР. ШИПКА",
        paragraphs: [
            "Научен семинар, посветен на траките и виното, ще се състои през месец февруари. Събитието се организира във връзка с професионалния празник на българските археолози, Деня на лозарите и винарите – Трифон Зарезан, успението на Св. Кирил Славянобългарски (14-ти февруари) и в памет и чест на Красимира Стефанова Стефанова – Георгиева, археолог и дългогодишен музеен работник в ИМ „Искра“ – гр. Казанлък.",
            "Програмата ще започне на 21 февруари /петък/ в 16:00 часа с откриване на изложбата „Древнoтракийски скални съоръжения и светилища на територията на Розовата долина“ с автор инж. Костадин Димов. Официалното откриване на Научния семинар ще се състои от 10:00 ч. на 22 февруари 2025г. /събота/ в Археокомплекс „Долината на тракийските царе“ край град Шипка.",
            "Във форума ще вземат участие български учени и специалисти в областта на тракийското културно наследство, виното, винопроизводството и др.",
            "Събитието ще се осъществи като съвместна инициатива на Европейската асоциация на фолклорните фестивали (ЕАФФ), научните мероприятия на Интердисциплинарна научна мрежа INIS и със специалната подкрепа на Община Казанлък, Археокомплекс  „Долина на тракийските царе“, Исторически музей „Искра“, гр. Казанлък и Eurofolk TV.",
            <>Повече информация може да откриете <a href='https://www.facebook.com/events/1308471740351034/?acontext=%7B%22event_action_history%22%3A[%7B%22mechanism%22%3A%22attachment%22%2C%22surface%22%3A%22newsfeed%22%7D]%2C%22ref_notif_type%22%3Anull%7D' target='_blank'>тук.</a></>
        ],
    
    },
]

export default news;