import React, { useRef, useState } from "react";
import GoogleMap from 'google-maps-react-markers'
import Marker, { defaultProps } from './Marker'
import objects from '../../data/sights/sights_all'
import "./Map.css"

const Map = () => {
    const mapRef = useRef(null)
	const [activeFilter, setActiveFilter] = useState("archeology")
	const [mapReady, setMapReady] = useState(false)
	const [filteredObjects, setFilteredObjects] = useState(objects)
    const [showObjectName, setShowObjectName] = useState(-1)

    const onGoogleApiLoaded = ({ map, maps }) => {
		mapRef.current = map
		setMapReady(true)
		onFilterClick(activeFilter)
	}
  
    const onFilterClick = (filter) => {
		setActiveFilter(filter)
        const filteredObjects = objects.filter(object => object.filter == filter)
        setFilteredObjects(filteredObjects)
    }

	const onMarkerClick = (id) => {
		const object = objects.find(object => object.id == id);
        if (mapRef.current) {
            mapRef.current.setZoom(12);
            mapRef.current.setCenter({ lat: object.lat, lng: object.lng })
        }
	}

    const onMarkerHoverIn = (id) => {
        setShowObjectName(id);
    }

    const onMarkerHoverOut = (id) => {
        setShowObjectName(-1);
    }

	const getFilterButtonClassName = (filter) => {
		if (activeFilter == filter) return "Map-filter-button active"
		return "Map-filter-button"
	}

    return (
        <div className="Map-container">
			<h3>Културно наследство</h3>
			<div className="Map-filter-buttons">
				<button className={getFilterButtonClassName("archeology")} onClick={() => onFilterClick("archeology")}>Археологическо</button>
				<button className={getFilterButtonClassName("architecture")} onClick={() => onFilterClick("architecture")}>Архитектурно</button>
				<button className={getFilterButtonClassName("history")} onClick={() => onFilterClick("history")}>Историческо</button>
				<button className={getFilterButtonClassName("abstract")} onClick={() => onFilterClick("abstract")}>Нематериално</button>
				<button className={getFilterButtonClassName("festival")} onClick={() => onFilterClick("festival")}>Фестивали</button>
			</div>
			<div className="Landing-map">
				<GoogleMap
					apiKey="AIzaSyCF8G9-4Mg08XkzItWXor_EaV0MSmiimXA"
					mapMinHeight={'60vh'}
					options={{
						scrollwheels: false,
					}}
					defaultCenter={defaultProps.center}
					defaultZoom={defaultProps.zoom}
					onGoogleApiLoaded={onGoogleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals
				>
					{filteredObjects.map(object => (
						<Marker
							id={object.id}
							lat={object.lat}
							lng={object.lng}
							markerid={object.name}
                            showname={showObjectName == object.id}
							onClick={() => onMarkerClick(object.id)}
                            onhoverin={() => onMarkerHoverIn(object.id)}
                            onhoverout={() => onMarkerHoverOut(object.id)}
							// draggable={true}
							// onDragStart={(e, { latLng }) => {}}
							// onDrag={(e, { latLng }) => {}}
							// onDragEnd={(e, { latLng }) => {}}
						/>
					))}
				</GoogleMap>
			</div>
		</div>
    )
}

export default Map;