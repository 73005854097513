import { useLayoutEffect, useRef, useState } from "react";
import objects from "../../data/map/objects";
import events from "../../data/events/events";
import {renderer, controls} from "../VirtualMuseum/main";
import "./DigitalResources.css";

const DigitalResources = () => {
	const mapRef = useRef(null)
	const [mapReady, setMapReady] = useState(false)
	const [chosenObject, setChosenObject] = useState(objects[0])
	const [chosenEvent, setChosenEvent] = useState(events[0])

	const onGoogleApiLoaded = ({ map, maps }) => {
		mapRef.current = map
		setMapReady(true)
	}
  
	const onMarkerClick = (id) => {
		const object = objects[id];
		setChosenObject(object);
		mapRef.current.setZoom(12);
		mapRef.current.setCenter({ lat: object.lat, lng: object.lng })
	}

	const hideMenu = () => {
		const menu = document.getElementById("virtual-museum-menu");
		menu.style.display = "none";
	}

	const showMenu = () => {
		const menu = document.getElementById("virtual-museum-menu");
		menu.style.display = "flex";
	}

	useLayoutEffect(() => {
		document.getElementById("scene").appendChild(renderer.domElement);
		document.getElementById("play-button").addEventListener("click", () => {
			controls.lock();
		}, false);
		controls.addEventListener("unlock", showMenu);
		controls.addEventListener("lock", hideMenu);

	}, [])

	return (
		<div className="DigitalResources">

			{/* <div className="Map-container">
				<div className="Map">
					<GoogleMap
						apiKey="AIzaSyBDJiCvHuyZgIkVWrDXAgJEBALc3nnmy0c"
						defaultCenter={defaultProps.center}
						defaultZoom={defaultProps.zoom}
						mapMinHeight="80vh"
						onGoogleApiLoaded={onGoogleApiLoaded}
					>
						{objects.map((object, index) => (
						<Marker
							key={index}
							lat={object.lat}
							lng={object.lng}
							markerId={object.name}
							onClick={() => onMarkerClick(index)}
							// draggable={true}
							// onDragStart={(e, { latLng }) => {}}
							// onDrag={(e, { latLng }) => {}}
							// onDragEnd={(e, { latLng }) => {}}
						/>
						))}
					</GoogleMap>
				</div>
				<div className="Map-object">
					<h1>{chosenObject.name}</h1>
					<iframe 
						width="90%"
						height="400px"
						src={chosenObject.video}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
					</iframe>
				</div>
			</div>
			<p className="Map-object-description">{chosenObject.description}</p> */}
			<h1 className="DigitalResourcesTitle">Религиозни храмове в област Стара Загора</h1>
			<div id="scene">
				<div id="painting-info"></div>
				<div id="virtual-museum-menu">
					<h2>Виртуален Музей</h2>
					<p>Това е интерактивен виртуален музей. Можеш да се разходиш, огледаш, да прочетеш за всеки от експонатите.
					Щом свършиш, натисни ESC, за да се върнеш обратно към сайта.</p>
					<button id="play-button">Влез</button>
				</div>
			</div>
		</div>
	)
}

export default DigitalResources;